import React from 'react';
import PropTypes from 'prop-types';

import { renderPage } from './RenderPage';

const RenderPageTemplate = ({ pageContext }) => (
  <div>{renderPage(pageContext.pageData)}</div>
);

RenderPageTemplate.propTypes = {
  pageContext: PropTypes.shape({
    pageData: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
};

export default RenderPageTemplate;
